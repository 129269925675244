import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link, 
  Redirect
} from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
class CuadranteHorizontal extends React.Component{
  render(){
    return(
      <div class="cuadranteHorizontal">
        <Link to={this.props.to} class="sinDecoracionHorizontales">
        <img class="imagenCuadranteHorizontal" src={this.props.img} />
        <div class="textoCuadranteHorizontal">
          <div class="productoCuadranteHorizontal">
            {this.props.producto} desde
          </div>
          
        </div>
        <div class="infoPrecioHorizontal">
          <div class="bloquePrecio">
            <div class="precio">
              <div class="valor">{this.props.valor}</div><div class="euro">€</div>
            </div>
            <div class="textoParticipacion">
              Participación
            </div>
          </div>    
        </div>
        </Link>
      </div>
    );
  }
};
class Cuadrante extends React.Component{
  render(){
    return(
      <div class="cuadrante">
        <Link to={this.props.to} class="sinDecoracion">
        <img class="imagenCuadrante" src={this.props.img} />
        <div class="textoCuadrante">
          <div class="productoCuadrante">
            {this.props.producto} desde
          </div>
          <div class="infoPrecio">
            <div class="precio">
              <div class="valor">{this.props.valor}</div><div class="euro">€</div>
            </div>
            <div class="textoParticipacion">
              Participación
            </div>
          </div>
        </div>
        </Link>
      </div>
    );
  }
};
class Menu extends React.Component {
  constructor(props){
    super(props);
    this.botonLogin=this.botonLogin.bind(this);
    this.mostrarUsuario=this.mostrarUsuario.bind(this);
  }
  botonLogin(){
    if(!this.props.isLoged){
      return(
        <div>
          <p class="textoMenu margen10">Inicia sesión o registrate para que tu experiencia sea mejor</p>
          <div class="cajaBotonMenu">
            <Link class="enlaceLogin" to="/login"><div class="enlaceLogin"> Inicia sesión / Crea una cuenta</div></Link>      
          </div>
        </div>     
      );
    }
    
  }
  mostrarUsuario(){
    if(this.props.Usuario!=""){
      return(
        <h2 class="nombre">{this.props.Usuario}</h2>
      );
    } 
  }
  componentDidMount(){
    window.scrollTo(0, 0);
  }
  render(){
    const images=[{original: './1.0.jpg'}]
    return (
      <div>
        {this.mostrarUsuario()}
        <p class="textoNavegadorMenu centrado">Electrónica - Hogar - Deporte - Moda</p>
        <p class="tituloMenu margen10">DEMUESTRA CUANTO LO QUIERES</p>
        <div class="carouselAutomatico">
        <Carousel showArrows={false} showStatus={false} showThumbs={false} autoPlay={true} infiniteLoop={true} interval={3000}>
          <div>
            <img src={require("./imagenes/miband.jpg")} />
          </div>
          <div>
            <img src={require("./imagenes/2.jpg")} />
          </div>
          <div>
            <img src={require("./imagenes/3.jpg")} />
          </div>
        </Carousel>
        </div>
        
        {
          this.botonLogin() 
        }
        <div class="cuadriculaResponsive">
          <div class="cuadricula">
            <Cuadrante img={require("./imagenes//xiaomi.jpg")} id="xiaomi" to="/xiaomi" producto="Xiaomi Mi 9" valor="30"/>
            <Cuadrante img={require("./imagenes//camara.jpg")} id="camara" to="/sonyCamara" producto="Sony C-S" valor="10"/>
          </div>
          <div class="cuadricula">
            <Cuadrante img={require("./imagenes//roomba.jpg")} id="roomba" to="/roomba" producto="Roomba 615" valor="20"/>
            <Cuadrante img={require("./imagenes//acer.jpg")} id="acer" to="/acer" producto="Acer Aspire" valor="45"/>
          </div>
        </div>
        <div class="bloqueFinal">
          <div class="contenedorProductoSolitario">
            <Link id="imac" to="imac"><img class="productoSolitario" src={require("./imagenes/imac.jpg")}/></Link>
          </div>
            
          <div>
            <CuadranteHorizontal img={require("./imagenes/gopro.jpg")} id="gopro" to="/gopro" producto="GoPro Hero" valor="35"/>
            <CuadranteHorizontal img={require("./imagenes/expresso/11.jpg")} id="cafetera" to="/cafetera" producto="De'longhi" valor="15"/>
          </div>
        </div>
        
      </div>
    );
  } 
};
export default Menu;
