import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link, 
  Redirect
} from "react-router-dom";
class Login extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      nombre: "",  
      contraseña: "",
      isLoged: false,
      mensaje: "",
      stop: true,
    };
  }
  handleBoton(){
    //fetch('https://tests.rocksel.com/saveResults.php?datetime='+year.toString()+'-'+month.toString()+'-'+date.toString()+'-'+hours.toString()+':'+min.toString()+':'+sec.toString()+'&user='+jug.toString()+'&casillas='+stringCasillas+'&ganador='+ganador.toString()+'&resultado='+res.toString());
    //var request=fetch('https://tests.rocksel.com/login.php?email='+(this.state.nombre).toString()+'&password='+(this.state.contraseña).toString());
    var request;
    fetch('https://tests.rocksel.com/login.php?email='+(this.state.nombre).toString()+'&password='+(this.state.contraseña).toString())
        .then(response => response.json())
        .then(data => {
            request=data;
            console.log(request.code);
            if(request.code!=1){
                this.setState({mensaje: request.desc})
            }
            else{
                //this.props.history.push('/juego')
                this.setState({nombre: request.nombre});
                this.setState({isLoged: true});
                this.props.enviarVariable(this.state.isLoged);
                this.props.getNombre(this.state.nombre);
                //return "textoBoton";
            }
        });
  }
  espera(){
    setTimeout(() => {
      this.setState({stop: false});
    }, 100);
  }
  render(){
    if(this.state.isLoged){
      this.espera();
      if(!this.state.stop){
        return <Redirect to={this.props.volver}/>
      } 
    }
    return (
      <div class="bodyLogin">
        <p class="tituloLogin">BIENVENIDO A UNA NUEVA FORMA DE COMPRAR</p>
        <div class="bloqueRadio">
          <input type="radio" id="radioDes" ></input>
          <p class="textoRadio">Crear cuenta. ¿Eres nuevo en Rocksel?</p>
        </div>
        <div class="bloqueRadio">
          <input type="radio" checked="checked"></input>
          <p class="textoRadio">Iniciar sesión. ¿Ya eres cliente?</p>
        </div>
        <div class="mensajeError">
          {this.state.mensaje}
        </div>
        <input className="cajaLogin" type="text" placeholder="Tu e-mail" value={this.state.nombre} onChange={evt => {
              this.setState({nombre: evt.target.value})
          }}>
        </input>
        <input className="cajaLogin" type="password" placeholder="Tu contraseña" value={this.state.contraseña} onChange={evt => {
              this.setState({contraseña: evt.target.value})
          }}>
        </input>
        <button to="/" id="link" className="cajaBoton" 
         onClick={()=> 
            {
                this.handleBoton();
            }
        }>
          Continuar
        </button>
      </div>
    );
  }
  
};

export default Login;
