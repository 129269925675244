import React from 'react';
import logo from './logo.svg';
import './App.css';
import Login from './Login.js';
import Menu from './Menu.js';
import Juego from './Juego.js';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link, 
  Redirect
} from "react-router-dom";

class App extends React.Component {
  constructor(props){
    super(props);
    this.state={
      loged : false,
      nombre : "",
      idProducto : "",
    }
    this.getLogin=this.getLogin.bind(this);
    this.getUser=this.getUser.bind(this);
    this.getIdProducto=this.getIdProducto.bind(this);
  }
  getLogin(val){
    this.setState({loged: val});
  }
  getUser(val){
    this.setState({nombre: val});
  }
  getIdProducto(val){
    this.setState((state)=>({idProducto: val}));
  }
  mostrarBotonLogin(){
    if(!this.state.loged){
      return(<Link to="/login"><img src={require("./imagenes/loginHeader.png")}></img></Link>);
    }
    else{
      return(<img src={require("./imagenes/loginNegro.png")} ></img>);
    }
  }
  render(){
    var xiaomi=[require("./imagenes/xiaomi/1.jpg"), require("./imagenes/xiaomi/2.jpg"), require("./imagenes/xiaomi/3.jpg"), require("./imagenes/xiaomi/4.jpg")];
    var sony=[require("./imagenes/sonyCyber/1.jpg"), require("./imagenes/sonyCyber/2.jpg"), require("./imagenes/sonyCyber/3.jpg"), require("./imagenes/sonyCyber/4.jpg")];
    var roomba=[require("./imagenes/roomba/1.jpg"), require("./imagenes/roomba/2.jpg"), require("./imagenes/roomba/3.jpg"), require("./imagenes/roomba/4.jpg")];
    var acer=[require("./imagenes/acer/1.jpg"), require("./imagenes/acer/2.jpg"), require("./imagenes/acer/3.jpg"), require("./imagenes/acer/4.jpg")];
    var gopro=[require("./imagenes/gopro/1.jpg"), require("./imagenes/gopro/2.jpg"), require("./imagenes/gopro/3.jpg"), require("./imagenes/gopro/4.jpg")];
    var cafetera=[require("./imagenes/expresso/9.jpg"),require("./imagenes/expresso/8.jpg"), require("./imagenes/expresso/5.jpg"), require("./imagenes/expresso/6.jpg"), require("./imagenes/expresso/7.jpg")];
    var imac=[require("./imagenes/mac/1.jpg"), require("./imagenes/mac/2.jpg"), require("./imagenes/mac/3.jpg"), require("./imagenes/mac/4.jpeg")];
    return (
      <Router>
      <div>
        <header>
          <img src={require("./imagenes//botonHeader.png")}></img>
          <Link to="/"><img src={require("./imagenes//tituloRocksel.png")} class="rockselEnlace"></img></Link>
          {this.mostrarBotonLogin()}
        </header>
        <Switch>
          <Route path="/imac">    
            <Juego nombre={this.state.nombre} valor={115} casillas={10} id={"/imac"} producto={"iMac Core 2 Duo"} imagenes={imac} volver={this.getIdProducto}/>
          </Route>
          <Route path="/cafetera">      
            <Juego nombre={this.state.nombre} valor={15} casillas={12} id={"/cafetera"} producto={"De'longhi Dedica EC685.M"} imagenes={cafetera} volver={this.getIdProducto}/>
          </Route>
          <Route path="/gopro">      
            <Juego nombre={this.state.nombre} valor={35} casillas={12} id={"/gopro"} producto={"GoPro Hero 8"} imagenes={gopro} volver={this.getIdProducto}/>
          </Route>
          <Route path="/acer">      
            <Juego nombre={this.state.nombre} valor={45} casillas={15} id={"/acer"} producto={"Acer Aspire E15"} imagenes={acer} volver={this.getIdProducto}/>
          </Route>
          <Route path="/roomba">      
            <Juego nombre={this.state.nombre} valor={20} casillas={10} id={"/roomba"} producto={"Roomba 615"} imagenes={roomba} volver={this.getIdProducto}/>
          </Route>
          <Route path="/xiaomi">      
            <Juego nombre={this.state.nombre} valor={30} casillas={12} id={"/xiaomi"} producto={"Xiaomi Mi 9"} imagenes={xiaomi} volver={this.getIdProducto}/>
          </Route>
          <Route path="/sonyCamara">      
            <Juego nombre={this.state.nombre} valor={10} casillas={10} id={"/sonyCamara"} producto={"Sony Cyber-Shot DSC-W800"} imagenes={sony} volver={this.getIdProducto}/>
          </Route>
          <Route path="/login">      
            <Login enviarVariable={this.getLogin} getNombre={this.getUser} volver={this.state.idProducto}/>
          </Route>
          <Route path="/">      
            <Menu isLoged={this.state.loged} Usuario={this.state.nombre}/>
          </Route>
        </Switch>
      </div>
    </Router>
    );
  }
    
};

export default App;
