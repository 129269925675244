import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link, 
  Redirect
} from "react-router-dom";
import { animateScroll } from "react-scroll";
class Casilla extends React.Component{
  render(){
      var a = this.props.val;
      a = a.toString();
      return (
          <button className={this.props.clase} id={a} onClick={this.props.onClick}>{this.props.val}</button>
      );
  }
}
class Juego extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            casillasSeleccionadas: 0,
            precioCasilla:this.props.valor,
            listaCasillas :[],
            ganadorSorteo : "",
            casillasTotales: this.props.casillas,
            mostrado:0,
            jugador: this.props.nombre,
            mensaje: null,   
            casillasCanceladas: false,   
            primeraJugada: true, 
            casillaSinRegistro: false,
        };
        this.casillasActivas = 0;
        this.actualizar = this.actualizar.bind(this);
        this.procesar= this.procesar.bind(this);
        this.renderCasilla=this.renderCasilla.bind(this);
        this.limpiar = this.limpiar.bind(this); 
        this.handleClick =this.handleClick.bind(this);
        this.mostrarAyuda = this.mostrarAyuda.bind(this);
        this.eliminarAyuda = this.eliminarAyuda.bind(this);
    }

    handleClick(i){
        if(this.state.jugador==""){
            console.log("NO REGISTRADO");
            
            this.setState({casillaSinRegistro: true});
        }
        else{
            var lista = this.state.listaCasillas;
            var maximo = this.state.casillasTotales*0.6
            //Si no hemos seleccionado las casillas maximas podemos seguir seleccionando
            if(this.state.casillasCanceladas==false){
                if(this.state.casillasSeleccionadas<maximo){
                    if(lista[i]==0){
                        lista[i]=1;
                    }
                    else{
                        lista[i]=0;
                    }
                }
                //Cuando ya hemos seleccionado todas solo podemos quitar casillas
                else{
                    if(lista[i]==1){
                        lista[i]=0;
                    }
                }
                this.setState({listaCasillas: lista})
            }
        }
        

    }
    renderCasilla(i){
        var cas = <Casilla clase={"casilla"} val={i} onClick={() => this.handleClick(i)}/>;
        var aux = this.state.listaCasillas;
        if(aux[i]==1){
            cas = <Casilla clase={"casillaPulsada"} val={i} onClick={() => this.handleClick(i)}/>;
        }
        return cas;
    }
    muestraGanador(msg){ 
        setTimeout(() => {
            console.log("GANADOOOR");
            var bloque = document.getElementById("mensajeGanador");
            var mensaje = document.createElement("div");
            mensaje.setAttribute("id","resultado");
            mensaje.classList.add("mensajeGanador");
            var texto = document.createTextNode(msg);
            mensaje.append(texto);
            bloque.append(mensaje);

            var volver = document.createElement("button");
            volver.setAttribute("id","volver")
            volver.addEventListener("click", ()=> this.limpiar());
            volver.classList.add("textoVolver");
            var texto =document.createTextNode("Volver a jugar");
            volver.appendChild(texto);
            bloque.appendChild(volver);
            window.scrollTo(0, 100000);

        }, 1000);
    }
    //Cuando pulsamos el boton de jugar
    procesar(){
        if(this.state.casillasSeleccionadas==0){
            alert("Debes seleccionar una casilla como mínimo.");
        }
        else{
            var ganador = Math.floor((Math.random() * this.state.casillasTotales) + 1); //Generamos el ganador del sorteo
            //var ganador = 4;
            this.setState({ganadorSorteo : ganador});
            var lista =this.state.listaCasillas;

            var res=0;
            if(lista[ganador]==1){
              res=1;
            }
             //Borramos el boton de jugar
            var boton = document.getElementById("boton");
            boton.parentNode.removeChild(boton);
            this.setState({casillasCanceladas: true}); //Cancelamos las casillas para no poder modificar ninguna
            //Definimos las duraciones
            var inicioModal=300;
            var duracionModal=3000;  
            var inicioAnimación=500;
            //Abre modal
            setTimeout(() => {
                document.getElementById("miModal").style.opacity=1;
                console.log("realizando pago");
            }, inicioModal);
            //Cierra Modal
            setTimeout(() => {
                console.log("pago realizado");
                document.getElementById("miModal").style.opacity=0;
            }, inicioModal+duracionModal);
            //Inicia Animacion
            setTimeout(() => {
                this.animacion(ganador);
            },inicioModal+duracionModal+inicioAnimación);

            //Recogemos los datos de la participación
            var date = new Date().getDate(); //Current Date
            var month = new Date().getMonth() + 1; //Current Month
            var year = new Date().getFullYear(); //Current Year
            var hours = new Date().getHours(); //Current Hours
            var min = new Date().getMinutes(); //Current Minutes
            var sec = new Date().getSeconds(); //Current Seconds
            var stringCasillas="";
            var jug = this.state.jugador;
            for (var i =0; i< this.state.casillasTotales; i++){
                if(lista[i]==1){
                    stringCasillas+=i+'|';
                }
            }
            fetch('https://tests.rocksel.com/saveResults.php?datetime='+year.toString()+'-'+month.toString()+'-'+date.toString()+'-'+hours.toString()+':'+min.toString()+':'+sec.toString()+'&user='+jug.toString()+'&casillas='+stringCasillas+'&ganador='+ganador.toString()+'&resultado='+res.toString()+'&tablero='+this.props.id);
        }
    }
    //Función que hacer parpadear a las casillas en el momento del sorteo
    animacion(ganador){
        var casillero = document.getElementById("casillero");
        var casillas = casillero.childNodes;
        let intervalId;
        var contCasillas =0;
        var tiempo = 0;
        var tiempoComienzo= 50;
        var vueltas =0;
        var cont=1.1;
        var listaC = this.state.listaCasillas;
        var enlentecer = (ganador-6);
        var vuelta3=true;
        var rapidez=4.5;
        if(enlentecer<0){
            vuelta3=false;
            enlentecer=(enlentecer+(this.state.casillasTotales))%(this.state.casillasTotales);
        }
        console.log("ENLENTECER: "+ enlentecer);
        console.log("Ganador: "+ ganador);
        var lento=false;
    
        var contLento=0;
        const funcion = ()=>{
            let timeoutId=setTimeout(() => {
                casillas[contCasillas].classList.remove("casilla");
                casillas[contCasillas].classList.remove("casillaPulsada");
                casillas[contCasillas].classList.add("casillaParpadeo");
            }, 0);
            if(listaC[contCasillas+1]==0){
                casillas[contCasillas].classList.remove("casillaParpadeo");
                casillas[contCasillas].classList.add("casilla");
            }
            else{
                casillas[contCasillas].classList.remove("casillaParpadeo");
                casillas[contCasillas].classList.add("casillaPulsada");
            }
            contCasillas++;
            if(contCasillas==this.state.casillasTotales){
                vueltas++;
            }
            contCasillas=contCasillas%this.state.casillasTotales;
            clearInterval(intervalId);
            //tiempoComienzo +=(cont*cont);
            tiempo+=tiempoComienzo;
            intervalId = setInterval(funcion, tiempoComienzo);
            //Activamos la casilla ganadora 
            if(contCasillas==ganador-1 && vueltas == 3){
                this.activarCasillaGanadora(ganador,contCasillas);
                clearInterval(intervalId);
                clearTimeout(timeoutId);
            }
            //Aumentamos el contador al final de cada vuelta
            if(contCasillas==this.state.casillasTotales-1){
                cont+=(0.2*(Math.pow((vueltas+1),3)));
                tiempoComienzo +=(cont*cont);
            }
            //Si se debe empezar a enlentecer en la vueta 3
            if(vuelta3){
                if(contCasillas==(enlentecer%this.state.casillasTotales) && vueltas==3){
                    console.log("vuelta3");
                    lento=true;
                }
            }
            //Si se debe empezar a enlentecer en la vuelta 2
            else{
                if(contCasillas==(enlentecer%this.state.casillasTotales) && vueltas==2){
                    lento=true;
                    console.log("vuelta2");
                }
            }
            if(lento){
                if(vueltas==2){
                    tiempoComienzo+=(Math.pow(rapidez,3));
                    console.log("CONTADOR:" +tiempoComienzo);
                    rapidez++;
                }
                else{
                    tiempoComienzo+=(Math.pow(rapidez,3));
                    console.log("CONTADOR:" +tiempoComienzo);
                    rapidez++;
                }           
                console.log("Lento "+contLento);
                contLento++;
            }     
        }
        intervalId=setInterval(funcion, tiempo);
    }   
    //Activamos la casilla ganadora y mostramos el mensaje de información al participante
    activarCasillaGanadora(ganador,contCasillas){
        var casillero = document.getElementById("casillero");
        var casillas = casillero.childNodes;
        var listaC = this.state.listaCasillas;
        casillas[contCasillas].classList.remove("casilla");
        casillas[contCasillas].classList.remove("casillaPulsada");
        if(listaC[ganador]==1){
            casillas[contCasillas].classList.add("casillaGanadora");
        }
        else{
            casillas[contCasillas].classList.add("casillaPerdedora");
        }
        var msg="Lo sentimos, no has tenido suerte.";
        if(listaC[ganador]==1){
            msg="¡Enhorabuena! Has ganado.";
        }
        this.muestraGanador(msg);
    }
    //Inicializa la lista de casillas a 0 al cargar la página
    componentDidMount(){
        window.scrollTo(0, 0);
        this.props.volver(this.props.id);
        var lista=[]
        for(var i=0; i<this.state.casillasTotales+1; i++){
            lista.push(0);
        }
        this.setState({listaCasillas : lista});

    }
    //Cada vez que hacemos click en el bloque de casillas se actualiza el contador de casillas seleccionadas
    actualizar(){
        var contador =0;
        var lista = this.state.listaCasillas;
        for(var i=0; i<lista.length; i++){
            if(lista[i]==1) contador++;
        }
        this.setState({casillasSeleccionadas: contador})
    }
    //Limpiamos el tablero para poder jugar de nuevo
    limpiar(){
        var lista = Array(this.state.casillasTotales+1).fill(0);
        this.setState({
            listaCasillas: lista,
            mensaje: null,
            ganadorSorteo: "",
            casillasSeleccionadas: 0,
        })
        var casillero = document.getElementById("casillero");
        var casillas = casillero.childNodes;

        for (var i=0; i<this.state.casillasTotales; i++){
            if(casillas[i].classList.contains("casillaGanadora") || casillas[i].classList.contains("casillaPerdedora") ){
                casillas[i].classList.remove("casillaGanadora");
                casillas[i].classList.remove("casillaPerdedora");                
                casillas[i].classList.add("casilla");
            }
        }


        var boton = document.getElementById("volver");
        boton.parentNode.removeChild(boton);
        var mensaje = document.getElementById("resultado");
        mensaje.parentNode.removeChild(mensaje);
        var bloque = document.getElementById("mensajeGanador");
        var boton = document.createElement("button");
        var texto = document.createTextNode("Jugar");
        boton.append(texto);
        boton.classList.add("botonJugar");
        boton.setAttribute("id","boton");
        boton.addEventListener("click", ()=> this.procesar());
        bloque.appendChild(boton);
        this.setState({casillasCanceladas: false});
    }
  
    mostrarAyuda(){
        if(this.state.primeraJugada){
            return (
            <div class="mensajeAyuda">
                <h3>¿Cómo se juega?</h3>
                <p><font color="red">1.</font> Selecciona cuántos números de abajo prefieras para aumentar tus participaciones. Todos los números valen lo mismo.</p>
                <p><font color="red">2.</font> Arriba podrás ver como aumentan tus posibilidades de ganar</p>
                <p><font color="red">3.</font> Cuando termines tu selección, pulsa jugar. ¡Demuestra cuánto lo quieres!</p>
                <button class="botonAyuda" onClick={this.eliminarAyuda}>Entendido</button>
            </div>
            );
        } 
    }
    eliminarAyuda(){
        document.getElementById("mAyuda").style.opacity=0;
        var hijo=document.getElementById("mAyuda");
        hijo.parentNode.removeChild(hijo);

    }
    mostrarModal(){
        return(
            <div id="miModal" class="modal">
                <div class="modal-contenido">
                    <h3>Realizando Pago</h3>
                    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div></div>
                </div>  
            </div>
        );
    }
    render() {
        const casillas = [];
        for(var i=0; i<this.state.casillasTotales; i++){
            casillas.push(this.renderCasilla(i+1)); 
          
        }
        const imagenes = this.props.imagenes;
        const slider =[];
        for(var i=0; i<imagenes.length; i++){
            slider.push(<img src={imagenes[i]} class="slide2"></img>)
        }
        if(this.state.casillaSinRegistro){
            this.setState({casillaSinRegistro: false});
            this.props.volver(this.props.id);
            return(<Redirect to='/login' from={this.props.id}/>);
        }
        else{
            return (
                <div>
                    {this.mostrarModal()}
                    <div id="mAyuda" class="modalAyuda">
                        {this.mostrarAyuda()}
                    </div>
                    <div class="usuario">{this.state.jugador} </div>
                    <div class="slider2">
                        {slider}
                    </div>
                    <div className="tituloProducto">{this.props.producto}</div>
                    <div className="infoProducto" onClick={() => window.open("https://www.mi.com/es/mi-smart-band-4/")}>+ info del producto</div>
                    <div className="bloque1">
                        <div className="subbloque1">
                            <div className="numero">
                                {(this.state.casillasSeleccionadas*this.state.precioCasilla).toFixed(2)}€
                            </div>
                            <div className="info2">
                            {this.state.casillasSeleccionadas} participaciones
                            </div>
                        </div>
                    </div>
                    <div className="bloqueCasillas" id="casillero" onClick={this.actualizar}>
                        {casillas}
                    </div>
                        <div id="mensajeGanador">
                            <button class="botonJugar" id="boton" onClick={this.procesar} align="center">Jugar</button>
                        </div>
                        <div className="final"></div>
                </div>      
            );
        }
        
    }
}   

export default Juego;
